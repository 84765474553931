import { Controller } from 'stimulus';
import { format } from 'date-fns';

// date-fns format documentation:
// https://date-fns.org/v1.30.1/docs/format

export default class LocalTime extends Controller {
  static targets = ['time'];

  connect() {
    this.updateTimes();
  }

  updateTimes() {
    this.timeTargets.forEach((timeTarget) => {
      this.updateTime(timeTarget);
    });
  }

  updateTime(timeTarget) {
    const timeAt = timeTarget.dataset.time * 1000;
    const date = new Date(timeAt);
    timeTarget.innerHTML = format(date, 'MMM d, yyyy h:mm a');
  }
}
