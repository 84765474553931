/* global $ */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['shirt'];

  connect() {
    this.splitShirts();
  }

  splitShirts() { // eslint-disable-line class-methods-use-this
    if ($('.todays-shirts[data-order="newest"]').length === 0) {
      return;
    }

    const $lineSplit = $('.js-line-split');
    if (!$lineSplit) {
      return;
    }

    const $shirts = $('.todays-shirts .shirt:visible');
    const shirtsCount = $shirts.length;
    const dayAgo = (Math.floor(Date.now() / 1000) - (24 * 60 * 60));
    const lastShirtToday = $shirts
      .filter(function shirtsToday() {
        const $shirt = $(this);
        return parseInt($shirt.data('promotion-started-at'), 10) > dayAgo;
      })
      .last();

    if (!lastShirtToday) {
      return;
    }

    const splitAfter = $(lastShirtToday).index();

    const divisorAfter = (index, divisable) => {
      const dividingIndex = (Math.ceil(index / divisable) * divisable) - 1;

      if (dividingIndex > 0 && dividingIndex < shirtsCount) {
        return dividingIndex;
      }

      return NaN;
    };

    $shirts.eq(divisorAfter(splitAfter, 2)).after(
      $lineSplit.clone().addClass('d-inline-block d-sm-none').show(),
    );

    $shirts.eq(divisorAfter(splitAfter, 4)).after(
      $lineSplit.clone().addClass('d-none d-sm-inline-block').show(),
    );
  }
}
