import $ from 'jquery';

$(() => {
  const $form = $('.form-remote-checkbox');
  $form.find('input[type=submit]').remove();

  return $form.on('click', 'input[type=checkbox]', () => {
    const $checkbox = $(this);
    const $spinner = $("<i class='form-remote-checkbox-spinner fa fa-spinner fa-pulse'></i>");

    $form.one('ajax:send', () => $checkbox.replaceWith($spinner));

    $form.one('ajax:complete', () => $spinner.replaceWith($checkbox));

    return $form.submit();
  });
});
