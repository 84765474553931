import { Controller } from 'stimulus';

export default class DeferredController extends Controller {
  static values = {
    url: String,
  };

  connect() {
    this.loadContent();
  }

  loadContent() {
    fetch(this.urlValue)
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }
}
