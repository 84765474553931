import jQuery from 'jquery';

jQuery.fn.putCursorAtEnd = function putCursorAtEnd() {
  this.each(() => {
    const $el = $(this);
    const el = this;

    if (!$el.is(':focus')) {
      $el.focus();
    }

    if (el.setSelectionRange) {
      const len = $el.val().length * 2;
      setTimeout((() => {
        el.setSelectionRange(len, len);
      }), 1);
    } else {
      $el.val($el.val());
    }

    this.scrollTop = 999999;
  });
};
