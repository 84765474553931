import { Controller } from 'stimulus';
import timeAgo from '../lib/time_ago';

const minute = 60 * 1000;

export default class TimeAgo extends Controller {
  static targets = ['time'];

  connect() {
    this.updateTimes();

    setInterval(() => {
      this.updateTimes();
    }, 1 * minute);
  }

  updateTimes() {
    this.timeTargets.forEach((timeTarget) => {
      this.updateTime(timeTarget);
    });
  }

  updateTime(timeTarget) {
    const timeAt = timeTarget.dataset.time * 1000;
    timeTarget.innerHTML = timeAgo(new Date(timeAt)); // eslint-disable-line no-param-reassign
  }
}
