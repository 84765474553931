/* global $, ga */
import { Controller } from 'stimulus';

export default class ModalController extends Controller {
  static targets = ['content'];

  connect() {
    this.createModalElement();
  }

  createModalElement() {
    const modalElement = document.createElement('div');
    modalElement.setAttribute('class', 'modal');
    modalElement.setAttribute('tabindex', '-1');
    modalElement.setAttribute('role', 'dialog');

    // Create initially hidden
    modalElement.setAttribute('style', 'display: none;');
    modalElement.setAttribute('aria-hidden', 'true');

    modalElement.innerHTML = `
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" data-modal-target="content"></div>
      </div>
    `;

    this.modalElement = modalElement;
    document.body.appendChild(modalElement);
  }

  show(event) {
    event.preventDefault();

    const { modalUrl } = event.currentTarget.dataset;

    this.contentTarget.innerHTML = `
      <div data-controller="deferred" data-deferred-url-value=${modalUrl}></div>
    `;
    $(this.modalElement).modal('show');

    if (ga) {
      ga('send', { hitType: 'pageview', page: modalUrl });
    }
  }
}
