import $ from 'jquery';
import timeAgo from '../lib/time_ago';

const percentageFromDate = (endsAt) => {
  const daysRemaining = (endsAt - Date.now()) / (24 * 60 * 60 * 1000);
  const percentageRemainingUnbounded = (1 - (4.0 ** (-1 * daysRemaining))) * 100;
  const percentageRemaining = Math.round(Math.max(0, percentageRemainingUnbounded));

  return percentageRemaining;
};

$(() => {
  $('.shirt .card-progress[data-promotion-ends-at]').each(function createProgressBar() {
    const $el = $(this);
    const endsAt = $el.attr('data-promotion-ends-at') * 1000;
    const hasEnded = endsAt <= Date.now();
    const percentageRemaining = percentageFromDate(endsAt);

    $el.html(`<div class="progress-bar" role="progressbar" aria-valuenow="${percentageRemaining}" aria-valuemin="0" aria-valuemax="100" style="width: ${percentageRemaining}%;"></div>`);

    const title = `Sale ${hasEnded ? 'ended' : 'ends'} ${timeAgo(new Date(endsAt))}`;

    return $el.attr({
      title,
      'data-bs-toggle': 'tooltip',
      'data-bs-placement': 'top',
      'data-bs-container': 'body',
    });
  });

  return $('[data-bs-toggle="tooltip"]').tooltip();
});
