import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['shirt'];

  static values = {
    ids: Array,
  };

  connect() {
    this.hideShirts();
    this.appendMessage();
  }

  appendMessage() {
    const { hiddenShirts } = this;

    if (hiddenShirts.length < 1) {
      return;
    }

    const explanation = (hiddenShirts.length === 1)
      ? `${hiddenShirts.length} shirt is`
      : `${hiddenShirts.length} shirts are`;

    this.element.insertAdjacentHTML('afterend', `
      <div class="alert alert-info" role="alert">
        ${explanation} hidden because of your <a href="/account/merchants">preferences</a>.
        </div>
    `);
  }

  hideShirts() {
    this.hiddenShirts.forEach((hiddenShirt) => { hiddenShirt.style.display = 'none'; }); // eslint-disable-line no-param-reassign
  }

  get hiddenShirts() {
    const hiddenMerchantIds = this.idsValue;
    return this.shirtTargets.filter(((shirtTarget) => hiddenMerchantIds.includes(parseInt(shirtTarget.getAttribute('data-merchant-id'), 10))));
  }
}
