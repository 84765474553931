import { Controller } from 'stimulus';

export default class FavoritesController extends Controller {
  static targets = ['favoriteShirt', 'shirt'];

  static values = {
    shirtIds: Array,
    shirtCounts: Object,
  };

  shirtIdsValueChanged() {
    this.render();
  }

  shirtCountsValueChanged() {
    this.render();
  }

  render() {
    this.favoriteShirtTargets.forEach(this.renderFavorite);
    this.shirtTargets.forEach(this.renderShirt);
  }

  renderShirt = (shirtTarget) => {
    const shirtId = parseInt(shirtTarget.dataset.shirtId, 10);
    const isFavorited = this.shirtIdsValue.includes(shirtId);

    if (isFavorited) {
      shirtTarget.classList.add('shirt-favorited');
    } else {
      shirtTarget.classList.remove('shirt-favorited');
    }
  };

  renderFavorite = (favoriteTarget) => {
    const shirtId = parseInt(favoriteTarget.dataset.shirtId, 10);
    const isFavorited = this.shirtIdsValue.includes(shirtId);

    if (window.app.currentUser.isPresent) {
      if (isFavorited) {
        favoriteTarget.innerHTML = `
        <button class="badge active" data-action="favorites#click" data-shirt-id="${shirtId}"><i class='fa fa-star' aria-hidden='true'></i> ${this.shirtCountsValue[shirtId] || 0}</button>
      `;
      } else {
        favoriteTarget.innerHTML = `
        <button class="badge" data-action="favorites#click" data-shirt-id="${shirtId}"><i class='fa fa-star-o' aria-hidden='true'></i> ${this.shirtCountsValue[shirtId] || 0}</button>
      `;
      }
    } else {
      favoriteTarget.innerHTML = `
        <button class="badge" data-shirt-id="${shirtId}"><i class='fa fa-star-o' aria-hidden='true'></i> ${this.shirtCountsValue[shirtId] || 0}</button>
      `;

      const $button = $(favoriteTarget).find('button');
      $button.tooltip({
        title: 'Sign up or sign in <br> to favorite t-shirts',
        html: true,
        placement: 'left',
        trigger: 'hover',
        container: 'body',
      }).click((event) => { event.preventDefault(); });
    }
  };

  click(event) {
    event.preventDefault();
    event.stopPropagation();

    const shirtId = parseInt(event.currentTarget.dataset.shirtId, 10);
    const isFavorited = this.shirtIdsValue.includes(shirtId);

    const favoriteTargets = this.favoriteShirtTargets.filter((el) => parseInt(el.dataset.shirtId, 10) === shirtId);

    favoriteTargets.forEach((favoriteTarget) => {
      favoriteTarget.innerHTML = `
        <button class='badge' disabled><i class='fa fa-spinner fa-pulse'></i> ${this.shirtCountsValue[shirtId] || 0}</button> 
      `;
    });

    window.fetch(`/shirts/${shirtId}/favorite`, {
      method: (isFavorited ? 'DELETE' : 'POST'),
    })
      .then(() => {
        const shirtCounts = this.shirtCountsValue;

        if (isFavorited) {
          this.shirtIdsValue = this.shirtIdsValue.filter((id) => id !== shirtId);
          shirtCounts[shirtId] -= 1;
        } else {
          this.shirtIdsValue = this.shirtIdsValue.concat([shirtId]);
          shirtCounts[shirtId] += 1;
        }
        this.shirtCountsValue = shirtCounts;

        this.render();
      })
      .catch((error) => {
        const shirtCounts = this.shirtCountsValue;

        if (error.status === 409) {
          if (isFavorited) {
            shirtCounts[shirtId] -= 1;
          } else {
            shirtCounts[shirtId] += 1;
          }
          this.shirtCountsValue = shirtCounts;
        } else if (error.status === 401) {
          // not authenticated
        }

        this.render();
      });
  }
}
