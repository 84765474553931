/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//
// jQuery and Popper.js loaded in config/webpack/environment.js
import 'bootstrap';

import 'jquery-ujs';
// import 'tablesorter';
// import 'scroll-depth';

import '../utilities/form_remote_checkbox';
import '../utilities/jquery_put_cursor_at_end';
import '../utilities/shirts_countdown';

import '../stimulus_app';

window.$ = $;
window.jQuery = $;

$(() => {
  $('[data-bs-toggle="tooltip"]').tooltip();
  $('[data-bs-toggle="popover"]').popover();

  // $.scrollDepth({
  //   elements: ['#footer'],
  // });
  //
  // $('.tablesorter').tablesorter({
  //   textAttribute: 'data-sort-value',
  // });
});
